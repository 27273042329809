@tailwind base;
@tailwind components;
@tailwind utilities;

/*
Gray: #888888
*/

/*************************/
/* GLOBAL STYLINGS */
/*************************/

@font-face {
  font-family: 'Candu-Condensed';
  src: url('/fonts/Candu-Condensed.otf') format('woff2'),
}

body {
  animation: fade-in 2s;
  color: #EE2E31;
  overflow-x: hidden;
  font-family: 'Candu-Condensed';
}

/*************************/
/* COMPONENT STYLINGS */
/*************************/

.images-slider .swiper-slide {
  opacity: 0.5;
}

.images-slider .swiper-slide-thumb-active {
  opacity: 1;
}

/*************************/
/* KEYFRAMES */
/*************************/

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*************************/
/* MEDIA QUERIES */
/*************************/

@media (max-width: 480px) {
  .hero-section {
    background-image: url("./images/IMG_4655.jpeg");
  }
}
